const News = (props) => {
  return {
    id: props.id,
    title: props.titulo,
    date: props.fecha,
    description: props.descripcion,
    link: props.link,
    video: props.video,
    thumbnail: props.imagen,
  };
};

export default News;
