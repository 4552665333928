import { useEffect, useState } from "react";
import { getAuthors } from "../services/data.service";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import * as htmlToImage from "html-to-image";

const Label = ({ id, authorName, includeQR, qrValue }) => {
  return (
    <Box
      className="label"
      component={Paper}
      variant="outlined"
      sx={{ width: includeQR ? "500px" : "400px", minHeight: "230px" }}
    >
      <Stack>
        <Divider
          sx={{
            border: "3px solid",
            borderImage:
              "linear-gradient(to right, #ee217e 25%, #ffff01 25%, #ffff01 50%,#3ab6fe 50%, #3ab6fe 75%, #c768e0 75%) 5;",
          }}
        />
        <Stack direction="row" sx={{ minHeight: "165px" }}>
          <Stack sx={{ flexGrow: 1, mt: 1, ml: 1 }}>
            <Typography variant="caption">Autor/a</Typography>
            <Typography variant="h5" sx={{ flexGrow: 1, fontWeight: "bold" }}>
              {authorName}
            </Typography>

            <List dense>
              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText primary="fotonoba.ar" />
              </ListItem>

              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <InstagramIcon />
                </ListItemIcon>
                <ListItemText primary="@fotonobaok" />
              </ListItem>

              <ListItem disablePadding>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <FacebookIcon />
                </ListItemIcon>
                <ListItemText primary="Encuentros FOTONOBA" />
              </ListItem>
            </List>
          </Stack>

          {includeQR && (
            <>
              <Divider orientation="vertical" flexItem />

              {qrValue === "author" ? (
                <Stack sx={{ mt: 1, maxWidth: "150px", alignItems: "center" }}>
                  <Typography variant="caption" sx={{ ml: 1 }} align="center">
                    Escaneá para ver más información del autor!
                  </Typography>

                  <img
                    alt="qr code"
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=fotonoba.ar/author/${id}`}
                    height={100}
                    width={100}
                  />
                </Stack>
              ) : (
                <Stack sx={{ mt: 1, maxWidth: "150px", alignItems: "center" }}>
                  <Typography variant="caption" sx={{ ml: 1 }} align="center">
                    Escaneá para ir al sitio de FOTONOBA!
                  </Typography>

                  <img
                    alt="qr code"
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=fotonoba.ar`}
                    height={100}
                    width={100}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>

        <Divider />

        <Stack sx={{ m: 1 }} direction="row">
          <Stack sx={{ flexGrow: 1 }}>
            <Typography
              sx={{ fontFamily: "Futuris", fontWeight: "bold" }}
              color="primary"
            >
              FOTONOBA
            </Typography>
            <Typography variant="caption" sx={{ fontFamily: "Pilcrow" }}>
              FOTÓGRAFOS DEL NOROESTE DE LA PCIA. DE BUENOS AIRES
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

const MembersLabels = () => {
  const [authors, setAuthors] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [includeQR, setIncludeQR] = useState(true);
  const [qrValue, setQRValue] = useState("author");

  const [selectedMember, setSelectedMember] = useState("");
  const [labelsCount, setLabelsCount] = useState(1);

  const [notMemberName, setNotMemberName] = useState("");
  const [notMemberLabelsCount, setNotMemberLabelsCount] = useState(1);

  useEffect(() => {
    setLoading(true);
    getAuthors().then((authors) => {
      setAuthors(authors);
      setLoading(false);
    });
  }, []);

  const print = () => {
    /*const input = document.getElementById("labels");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("labels.pdf");
    });*/

    /*htmlToImage
      .toPng(document.getElementById("labels"), { quality: 0.95 })
      .then(function (dataUrl) {
        //var link = document.createElement("a");
        //link.download = "my-image-name.jpeg";
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("etiquetas.pdf");
      });*/

    htmlToImage
      .toPng(document.getElementById("labels"), { quality: 0.95 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "etiquetas.png";
        link.href = dataUrl;
        link.click();
      });
  };

  return (
    <>
      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}
      {authors && (
        <>
          <Typography variant="h4" align="center" sx={{ my: 2 }}>
            Generador de etiquetas
          </Typography>

          <Paper sx={{ m: 2, p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Etiquetas para miembros de FOTONOBA
            </Typography>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <InputLabel id="select-label">Miembro</InputLabel>
                <Select
                  labelId="select-label"
                  value={selectedMember}
                  label="Miembro"
                  onChange={(e) => setSelectedMember(e.target.value)}
                >
                  {Object.entries(authors).map(([id, author]) => (
                    <MenuItem value={id}>{author.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Seleccioná un miembro de la lista, ingresá la cantidad de
                  etiquetas a generar y presioná agregar
                </FormHelperText>
              </FormControl>

              <TextField
                type="number"
                label="Cantidad"
                min={0}
                value={labelsCount}
                onChange={(e) => setLabelsCount(e.target.value)}
              />

              <Button
                variant="contained"
                sx={{ height: 56 }}
                onClick={() => {
                  setSelectedMembers((prev) => [
                    ...prev,
                    ...[
                      {
                        id: selectedMember,
                        count: Number(labelsCount),
                        isMember: true,
                      },
                    ],
                  ]);
                  setSelectedMember("");
                  setLabelsCount(1);
                }}
              >
                Agregar
              </Button>
            </Stack>
          </Paper>

          <Paper sx={{ m: 2, p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Etiquetas para personas no registradas
            </Typography>
            <Stack direction="row" spacing={2} sx={{ m: 2 }}>
              <TextField
                label="Nombre"
                helperText="Escribí el nombre completo, ingresá la cantidad de
                etiquetas a generar y presioná agregar"
                value={notMemberName}
                onChange={(e) => setNotMemberName(e.target.value)}
              />

              <TextField
                type="number"
                label="Cantidad"
                min={0}
                value={notMemberLabelsCount}
                onChange={(e) => setNotMemberLabelsCount(e.target.value)}
              />

              <Button
                variant="contained"
                sx={{ height: 56 }}
                onClick={() => {
                  setSelectedMembers((prev) => [
                    ...prev,
                    ...[
                      {
                        id: notMemberName,
                        count: Number(notMemberLabelsCount),
                        isMember: false,
                      },
                    ],
                  ]);
                  setNotMemberName("");
                  setNotMemberLabelsCount(1);
                }}
              >
                Agregar
              </Button>
            </Stack>
          </Paper>

          <Stack
            direction="row"
            sx={{
              m: 2,
              display: "flex",
              gap: 1,
              maxWidth: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {selectedMembers.map(({ id, count, isMember }, index) => (
              <Chip
                key={id}
                label={
                  isMember ? `${authors[id].name}: ${count}` : `${id}: ${count}`
                }
                onDelete={() => {
                  setSelectedMembers((prev) => [
                    ...prev.filter((item) => item.id !== id),
                  ]);
                }}
              />
            ))}
          </Stack>

          {selectedMembers?.length > 0 && (
            <>
              <Stack direction="row" spacing={1} sx={{ m: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeQR}
                      onChange={(e) => setIncludeQR(e.target.checked)}
                    />
                  }
                  label="Incluir QR en la etiqueta"
                />
              </Stack>
              {includeQR && (
                <Stack direction="row" spacing={1} sx={{ m: 2 }}>
                  <RadioGroup
                    row
                    value={qrValue}
                    onChange={(e) => setQRValue(e.target.value)}
                  >
                    <FormControlLabel
                      value="author"
                      control={<Radio />}
                      label="QR con datos del autor"
                    />
                    <FormControlLabel
                      value="site"
                      control={<Radio />}
                      label="QR con sitio de FOTONOBA"
                    />
                  </RadioGroup>
                </Stack>
              )}
            </>
          )}

          <Box
            id="labels"
            sx={{
              //m: 2,
              p: 2,
              display: "flex",
              gap: 2,
              maxWidth: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
              //width: "200mm",
              //minHeight: "297mm",
              //marginLeft: "auto",
              //marginRight: "auto",
            }}
          >
            {selectedMembers.map(({ id, count, isMember }) => (
              <>
                {[...Array(count)].map((e, i) => (
                  <Label
                    key={`${id}-${i}`}
                    id={id}
                    authorName={isMember ? authors[id].name : id}
                    includeQR={includeQR}
                    qrValue={isMember ? qrValue : "site"}
                  />
                ))}
              </>
            ))}
          </Box>

          {selectedMembers?.length > 0 && (
            <Box sx={{ m: 2 }}>
              <Button fullWidth variant="contained" onClick={() => print()}>
                Imprimir
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default MembersLabels;
