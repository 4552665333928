import { Box, Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import SunCalc from "suncalc2";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import MyLocationIcon from "@mui/icons-material/MyLocation";
import { DatePicker } from "@mui/x-date-pickers";
import ToolsBreadcrum from "./ToolsBreadcrum";

function time(date) {
  return moment(date).format("HH:mm:ss");
}

const Times = (props) => {
  const sunCalc = SunCalc.getTimes(
    props.date,
    props.geolocation.lat,
    props.geolocation.lng
  );

  return (
    <>
      <ul>
        <li>Amanecer: {time(sunCalc.dawn)}</li>
        <li>Inicio de salida del sol: {time(sunCalc.sunrise)}</li>
        <li>Final de salida del sol: {time(sunCalc.sunriseEnd)}</li>
        <li>Hora dorada de la mañana: {time(sunCalc.goldenHourEnd)}</li>
      </ul>
      <ul>
        <li>Inicio hora dorada de la tarde: {time(sunCalc.goldenHour)}</li>
        <li>Inicio de puesta del sol: {time(sunCalc.sunsetStart)}</li>
        <li>Final de puesta del sol: {time(sunCalc.sunset)}</li>
        <li>Oscuridad: {time(sunCalc.dusk)}</li>
      </ul>
    </>
  );
};

const GoldenHour = () => {
  const [date, setDate] = useState(moment(new Date()));
  const [latitude, setLatitude] = useState(() => {
    const latitude = localStorage.getItem("latitude");
    if (!latitude) {
      return 0;
    }

    return parseFloat(latitude);
  });
  const [longitude, setLongitude] = useState(() => {
    const longitude = localStorage.getItem("longitude");
    if (!longitude) {
      return 0;
    }

    return parseFloat(longitude);
  });

  const setLatitudeInLocalStorage = (value) => {
    setLatitude(value);
    localStorage.setItem("latitude", `${value}`);
  };

  const setLongitudeInLocalStorage = (value) => {
    setLongitude(value);
    localStorage.setItem("longitude", `${value}`);
  };

  const onClick = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitudeInLocalStorage(position.coords.latitude);
      setLongitudeInLocalStorage(position.coords.longitude);
    });
  };

  return (
    <>
      <ToolsBreadcrum>
        <Typography
          sx={{
            color: "text.primary",
            display: "flex",
            alignItems: "center",
          }}
        >
          Hora dorada
        </Typography>
      </ToolsBreadcrum>
      
      <Box>
        <Stack direction="row" spacing={2}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD/MM/YYYY"
              value={date}
              onChange={(newDate) => setDate(newDate)}
            />
          </LocalizationProvider>

          <Button
            variant="contained"
            color="primary"
            startIcon={<MyLocationIcon />}
            onClick={onClick}
          >
            Mi ubicación
          </Button>
        </Stack>

        <Typography variant="caption">
          {`Latitud: ${latitude} - Longitud: ${longitude}`}
        </Typography>

        <Times
          date={date.toDate()}
          geolocation={{ lat: latitude, lng: longitude }}
        />
      </Box>
    </>
  );
};

export default GoldenHour;
