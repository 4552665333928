import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import ToolsBreadcrum from "./ToolsBreadcrum";

const Hyperfocal = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//photopills.com/widgets/pphyperfocal.min.js?lang=es";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <ToolsBreadcrum>
        <Typography
          sx={{
            color: "text.primary",
            display: "flex",
            alignItems: "center",
          }}
        >
          Hiperfocales
        </Typography>
      </ToolsBreadcrum>
      <Box sx={{ width: "100%" }} id="pphyperfocal"></Box>
    </>
  );
};

export default Hyperfocal;
