import { Breadcrumbs, Link } from "@mui/material";
import { useNavigate } from "react-router";
import HandymanIcon from "@mui/icons-material/Handyman";

const ToolsBreadcrum = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs sx={{ my: 2 }}>
      <Link
        underline="hover"
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        color="inherit"
        onClick={() => navigate("/tools")}
      >
        <HandymanIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        Herramientas
      </Link>
      {children}
    </Breadcrumbs>
  );
};

export default ToolsBreadcrum;
