import { getNews } from "../services/data.service";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Masonry } from "@mui/lab";
import ShareIcon from "@mui/icons-material/Share";
import { share } from "../services/share.service";
import { useNavigate, useParams } from "react-router";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import YouTubeIcon from "@mui/icons-material/YouTube";

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { newsId } = useParams();

  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(() => {
    setLoading(true);
    getNews().then((results) => {
      setNews(results);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (newsId) {
      let foundNews = news.find((a) => a.id === newsId);
      setSelectedNews(foundNews);
    } else {
      setSelectedNews(null);
    }
  }, [news, newsId]);

  const shareNews = (news, newsId) => {
    share("Mirá esta noticia de FOTONOBA!", news.title, newsId);
  };

  return (
    <>
      <Typography variant="h5" sx={{my: 2}}>FOTONOBA en los medios!</Typography> 

      {loading && <CircularProgress sx={{ mt: 2 }} color="primary" />}

      {selectedNews ? (
        <>
          <Breadcrumbs sx={{ my: 2 }}>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => navigate("/news")}
            >
              <NewspaperIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Noticias
            </Link>
            <Typography
              sx={{
                color: "text.primary",
                display: "flex",
                alignItems: "center",
              }}
            >
              {selectedNews.title}
            </Typography>
          </Breadcrumbs>

          <Stack spacing={5}>
            {selectedNews.video ? (
              <iframe
                style={{
                  width: "100%",
                  aspectRatio: "16 / 9",
                }}
                src={`https://www.youtube.com/embed/${selectedNews.video}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            ) : (
              <img
                style={{
                  width: "100%",
                  "object-fit": "contain",
                }}
                src={selectedNews.thumbnail}
                title={selectedNews.title}
                alt={selectedNews.title}
              />
            )}
            <Box>
              <Chip
                color="primary"
                size="small"
                label={selectedNews.date}
                gutterBottom
              />
              <Typography gutterBottom variant="h5">
                {selectedNews.title}
              </Typography>
              <Typography gutterBottom variant="body2">
                {selectedNews.description}
              </Typography>

              <Stack direction="row" spacing={2}>
                {selectedNews.link && (
                  <Button
                    component="a"
                    target="_blank"
                    href={selectedNews.link}
                    variant="text"
                    startIcon={<NewspaperIcon />}
                  >
                    Leer
                  </Button>
                )}
                {selectedNews.video && (
                  <Button
                    component="a"
                    target="_blank"
                    href={selectedNews.video}
                    variant="text"
                    startIcon={<YouTubeIcon />}
                  >
                    Ver
                  </Button>
                )}
              </Stack>

              <Fab
                onClick={() => shareNews(selectedNews)}
                color="secondary"
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                }}
              >
                <ShareIcon />
              </Fab>
            </Box>
          </Stack>
        </>
      ) : (
        <Box>
          <Masonry spacing={2} columns={{ xs: 1, sm: 2, md: 3 }}>
            {news.map((news) => (
              <Card variant="outlined">
                <CardActionArea onClick={() => navigate(`/news/${news.id}`)}>
                  <img
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                    src={news.thumbnail}
                    title={news.title}
                    alt={news.title}
                  />
                  <CardContent>
                    <Chip
                      color="primary"
                      size="small"
                      label={news.date}
                      gutterBottom
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      {news.title}
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      {news.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>

                <Divider />
                <CardActions spacing={2} sx={{ justifyContent: "end" }}>
                  {news.link && (
                    <IconButton component="a" target="_blank" href={news.link}>
                      <NewspaperIcon />
                    </IconButton>
                  )}
                  {news.video && (
                    <IconButton
                      component="a"
                      target="_blank"
                      href={`https://www.youtube.com/watch?v=${news.video}`}
                    >
                      <YouTubeIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={() => shareNews(news, news.id)}>
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            ))}
          </Masonry>
        </Box>
      )}
    </>
  );
};

export default News;
