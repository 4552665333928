import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

const Tools = () => {
  return (
    <Stack spacing={2}>
      <Button
        variant="contained"
        size="large"
        component={Link}
        to="/tools/hiperfocal"
      >
        Hiperfocales
      </Button>
      <Button
        variant="contained"
        size="large"
        component={Link}
        to="/tools/goldenhour"
      >
        Hora dorada
      </Button>
      <Button
        variant="contained"
        size="large"
        component={Link}
        to="/tools/moon"
      >
        Luna
      </Button>
    </Stack>
  );
};

export default Tools;
